export const PATH_NAME = Object.freeze({
  // Private routes
  LEADERBOARD: "/leaderboard",
  ACCOUNT: "/account",
  NEWS_FEED: "/newsfeed",
  HOME_PAGE: "/",
  SETTINGS: "/settings",
  LOGIN: "/login",
  INSIGHTS: "/insights",
  PUBLIC_PROFILE: "/public_profile",
  HELP_CENTER: "/help_center",
  ADMIN: "/admin"
});
