import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { IDarkTheme } from "styles/darkTheme";

export const styles = makeStyles((theme: IDarkTheme) =>
  createStyles({
    contentWrapper: {
      display: "flex",
      flexDirection: "row",
      marginLeft: "15%",
      width: "85%",
      flexGrow: 1,
      backgroundColor: theme.colors.neutrals.neutrals_20,
      borderTopLeftRadius: "40px",
      height: "100%",
      "@media (max-width: 1370px)": {
        marginLeft: "0px",
        width: "100%"
      }
    },
    appContent: {
      width: "calc(100% - 276px)",
      minHeight: "100vh",
      flex: 1,
      padding: "24px 0px 24px 0",
      "@media (max-width: 750px)": {
        padding: "0px 0px"
      }
    }
  })
);
