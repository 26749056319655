export const QUERIES = Object.freeze({
  LOAD_CURRENT_USER: "LOAD_CURRENT_USER",
  LOAD_SLACK_AUTH_URLS: "LOAD_SLACK_AUTH_URLS",
  LOAD_USER_MONTHES_SCORES: "LOAD_USER_MONTHES_SCORES",
  LOAD_TEAM_SCORE_LATEST_MONTHS: "LOAD_TEAM_SCORE_LATEST_MONTHS",
  LOAD_TEAM_MONTHLY_REPORT: "LOAD_TEAM_MONTHLY_REPORT",
  LOAD_USER_YEARLY_SCORES: "LOAD_USER_YEARLY_SCORES",
  LOAD_MONTH_TOP_EMPLOYEES: "LOAD_MONTH_TOP_EMPLOYEES",
  SLACK_AUTH: "SLACK_AUTH",
  LOAD_FEEDS: "LOAD_FEEDS",
  LOAD_USER_PROFILE: "LOAD_USER_PROFILE",
  LOAD_USER_RANK: "LOAD_USER_RANK",
  LOAD_TEAM_INFO: "LOAD_TEAM_INFO",
  LOAD_REACTION: "LOAD_REACTION",
  LOAD_COMPANY_VALUES: "LOAD_COMPANY_VALUES",
  LOAD_QUARTER_POINTS_STATISTICS: "LOAD_QUARTER_POINTS_STATISTICS",
  LOAD_QUARTER_INSIGHTS: "LOAD_QUARTER_INSIGHTS",
  LOAD_TOP_MEMBERS_PER_VALUE: "LOAD_TOP_MEMBERS_PER_VALUE",
  LOAD_VALUES_USAGE_PER_MONTH: "LOAD_VALUES_USAGE_PER_MONTH",
  LOAD_INACTIVE_USERS: "LOAD_INACTIVE_USERS",
  LOAD_USER_STATISTICS: "LOAD_USER_STATISTICS",
  LOAD_SUPER_ADMINS: "LOAD_SUPER_ADMINS",
  ADD_SUPER_ADMIN: "ADD_SUPER_ADMIN",
  REMOVE_SUPER_ADMIN: "REMOVE_SUPER_ADMIN",
  LOAD_TEAM_MEMBERS: "LOAD_TEAM_MEMBERS",
  LOAD_USER_COMPANY_VALUE_STATS: "LOAD_USER_COMPANY_VALUES_STATS"
});
export const APIS = {
  AUTH: {
    BASE: "/auth",
    SETTINGS: "/auth/settings",
    SIGN_IN: "/auth/signIn",
    ADD_TO_WORK_SPACE: "/auth/addworkspace"
  },
  COMPANY_VALUES: {
    BASE: "/companyValues",
    COUNT: "/companyValues/count",
    MEMBER_STATS: "/companyValues/memberStats"
  },
  FEEDS: {
    BASE: "/feeds"
  },
  TEAM: {
    BASE: "/team",
    TOP_MEMBERS: "/team/topMembers",
    CURRENT_TEAM: "/team/current",
    SCORES: "/team/scores",
    MEMEBERS: "/team/members"
  },
  INSIGHTS: {
    BASE: "/insights",
    QUARTER_INSIGHTS: "/insights/quarterInsights",
    QUARTER_POINTS_STATISTICS: "/insights/quarterPointsStatistics",
    TOP_MEMBERS_PER_COMPANY_VALUE:
      "/insights/topMembersInCompanyValues",
    VALUE_USAGER_PER_MONTH: "/insights/valueUsagePerMonth",
    INACTIVE_USERS: "/insights/inactiveUsersPerMonth",
    USER_INSIGHTS: "/insights/accountInsights"
  },
  COMPANY_ADMIN: {
    BASE: "/companyAdmin"
  },
  ADMIN_ANALYTICS: {
    BASE: "/adminAnalytics",
    COMPANY_STATISTICS: "/adminAnalytics/companyStatistics",
    COMPANY_RETENTION: "/adminAnalytics/companyRetention",
    COMPANY_USERS_ACTIVITY: "/adminAnalytics/companyUsersActivity",
    GIVERS_RECIEVERS_CHAT: "/adminAnalytics/giversReceiversChart"
  },
  ROLES: {
    BASE: "/role",
    GET_SUPER_ADMINS: "/role/listSuperAdmins",
    ADD_SUPER_ADMIN: "/role/addSuperAdmin",
    REMOVE_SUPER_ADMIN: "/role/removeSuperAdmin"
  }
};
export const PER_FETCH_FEEDS_LIMIT = 12;
export const PER_FETCH_MEMBERS_LIMIT = 20;
export const Dates_filters = [
  "All",
  "Today",
  "This Week",
  "This Month",
  "Last Month"
];

export type UserRole = "COMPANY_ADMIN" | "USER";
export const UserRoles: { [k in UserRole]: UserRole } = {
  COMPANY_ADMIN: "COMPANY_ADMIN",
  USER: "USER"
};
