import { lazy } from "react";

export const Leaderboard = lazy(() => import("pages/leaderboard"));
export const NewFeeds = lazy(() => import("pages/newsFeed"));
export const Account = lazy(() => import("pages/account"));
export const Settings = lazy(() => import("pages/settings"));
export const SignIn = lazy(() => import("pages/signIn"));
export const LandingPage = lazy(() => import("pages/landingPage"));
export const Insights = lazy(() => import("pages/insights"));
export const PublicProfile = lazy(
  () => import("pages/publicProfile")
);
export const HelpCenter = lazy(() => import("pages/helpCenter"));
export const SuperAdminPanel = lazy(() => import("pages/superAdmin"));
