import { styles } from "./styles";
import LoadingImage from "assets/LoadingImage.svg";

const SuspenseLoading = () => {
  const classes = styles();

  return (
    <div className={classes.loaderWrapper}>
      <img
        src={LoadingImage}
        alt=""
        className={classes.loadingImage}
      />
    </div>
  );
};

export default SuspenseLoading;
