import { Skeleton } from '@mui/material';

interface ICustoSkeleton {
  height?: string | number;
  width?: string | number;
  className?: string;
}

const CustomSkeleton = ({
  className,
  height,
  width
}: ICustoSkeleton) => {
  return (
    <Skeleton
      className={className}
      animation="wave"
      height={height}
      variant="rectangular"
      width={width}
    />
  );
};

export default CustomSkeleton;
