import { PropsWithChildren } from "react";
import NewsFeedSkeleton from "../../newFeeds/skeleton";
import SuspenseLoading from "../suspenseLoading";

interface ILoadingAnimation {
  isNewsFeed?: boolean;
  isLoading: boolean;
  children?: any;
}

const LoadingAnimation = ({
  isNewsFeed = false,
  isLoading,
  children
}: PropsWithChildren<ILoadingAnimation>) => {
  if (isLoading)
    return (
      <>{isNewsFeed ? <NewsFeedSkeleton /> : <SuspenseLoading />}</>
    );
  return children;
};

export default LoadingAnimation;
