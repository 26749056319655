import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { ICustomTheme } from "styles/theme";
import "./loadingAnimation.css";

export const styles = makeStyles((theme: ICustomTheme) =>
  createStyles({
    loaderWrapper: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexGrow: 1,
      alignItems: "center",
      justifyContent: "center",
      cursor: "default"
    },
    loadingImage: {
      width: "40px",
      height: "40px",
      animation: "$spin 0.7s linear infinite forwards"
    },
    "@keyframes spin": {
      "0%": {
        transform: "rotate(0deg)"
      },
      "100%": {
        transform: "rotate(360deg)"
      }
    }
  })
);
