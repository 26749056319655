import { createContext, Suspense, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";

import { useAuthorizeSlack, useCurrentUser } from "../app/appHooks";
import LoadingAnimation from "components/SharedComponents/LoadingAnimation";
import { darkBackground } from "./darkBackground";
import AppLayout from "./privateRoutes/AppLayout";

import { PATH_NAME } from "routes/pathNames";
import * as LazyComponent from "utils/lazyComponents";
import RequireAuth from "./privateRoutes/RequireAuth";
import RequireSuperAdmin from "./superAdminRoutes/requireSuperAdmin";
export const SearchContext = createContext({
  searchTerm: "",
  setSearchTerm: (searchTerm: string) => {}
});

const AppRoutes = () => {
  const [tokenFetched, setTokenFetched] = useState(
    !!localStorage.getItem("token")
  );

  const { data, isLoading: authLoad } =
    useAuthorizeSlack(setTokenFetched);

  const { data: authenticatedUser, isLoading: currentUserLoading } =
    useCurrentUser(tokenFetched);

  const classes = darkBackground();

  return (
    <div className={classes.mainContainer}>
      <LoadingAnimation isLoading={authLoad || currentUserLoading}>
        <Suspense fallback={<LoadingAnimation isLoading={true} />}>
          <Router>
            <Routes>
              {tokenFetched && (data?.user || authenticatedUser) ? (
                //Authorized user routes
                <Route element={<AppLayout />}>
                  <Route
                    path={PATH_NAME.HOME_PAGE}
                    element={<LazyComponent.NewFeeds />}
                  />
                  <Route
                    path={PATH_NAME.NEWS_FEED}
                    element={<LazyComponent.NewFeeds />}
                  />

                  <Route
                    path={PATH_NAME.LEADERBOARD}
                    element={<LazyComponent.Leaderboard />}
                  />

                  <Route
                    path={`${PATH_NAME.ACCOUNT}/:userId`}
                    element={<LazyComponent.Account />}
                  />
                  {/* company admin Routes */}
                  <Route
                    element={
                      <RequireAuth
                        allowedRoles={["COMPANY_ADMIN"]}
                      ></RequireAuth>
                    }
                  >
                    <Route
                      path={PATH_NAME.INSIGHTS}
                      element={<LazyComponent.Insights />}
                    />
                    <Route
                      path={PATH_NAME.SETTINGS}
                      element={<LazyComponent.Settings />}
                    />
                  </Route>

                  <Route
                    path={PATH_NAME.HELP_CENTER}
                    element={<LazyComponent.HelpCenter />}
                  />
                  <Route path="/*" element={<div>not found</div>} />
                </Route>
              ) : (
                //Unauthorized user routes
                <>
                  <Route
                    path={PATH_NAME.HOME_PAGE}
                    element={<LazyComponent.LandingPage />}
                  />
                  <Route
                    path={PATH_NAME.LOGIN}
                    element={<LazyComponent.SignIn />}
                  />
                  <Route
                    path="/*"
                    element={<LazyComponent.SignIn />}
                  />
                </>
              )}
              {/*Public Routes*/}
              <Route
                path={`${PATH_NAME.PUBLIC_PROFILE}/:userId`}
                element={<LazyComponent.PublicProfile />}
              />
              {/* Super Admin routes ---- DISABLED TILL PRODUCTION
              <Route element={<RequireSuperAdmin />}>
                <Route
                  path={PATH_NAME.ADMIN}
                  element={<LazyComponent.SuperAdminPanel />}
                />
              </Route>
              */}
            </Routes>
          </Router>
        </Suspense>
      </LoadingAnimation>
    </div>
  );
};

export default AppRoutes;
