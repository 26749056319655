import mixpanel from "mixpanel-browser";

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN || "", {
  debug: process.env.NODE_ENV === "development"
});

const trackEvent = (name: string, props?: object) => {
  return mixpanel.track(name, props);
};

export default trackEvent;
