import Auth from "../../utils/auth";

export const isHandlerEnabled = (config = {}) => {
  return config.hasOwnProperty.call("handlerEnabled") &&
    !config.handlerEnabled
    ? false
    : true;
};

export const requestHandler = (request) => {
  if (isHandlerEnabled(request)) {
    request.headers.Authorization = `Bearer ${Auth.accessToken()}`;
  }
  return request;
};

export const successHandler = (response) => {
  if (isHandlerEnabled(response)) {
    // DO SOMETHING
  }
  return response;
};

export const errorHandler = (error) => {
  if (isHandlerEnabled(error.config)) {
    // DO SOMETHING
  }
  return Promise.reject({ ...error });
};
