import { UserRole } from "utils/constants";
import { axiosInstance } from "./index";
export type UserID = number;
export type IUserResponse = {
  id: UserID;
  teamId: number;
  email: string;
  phone?: string;
  title?: string;
  firstName: string;
  lastName: string;
  displayName: string;
  image512: string;
  image48: string;
  plusCount: number;
  minusCount: number;
  score: number;
  memberId: number;
  externalTeamId: string;
  externalId: string;
  integrationType: string;
  role: UserRole;
};

export const currentUser = async (): Promise<IUserResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get("/users/current")
      .then((res) => {
        const data = res.data;
        data.score = data.plusCount - data.minusCount;
        resolve(data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getUser = async (
  userId: string | number
): Promise<IUserResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`/users/${userId}`)
      .then((res) => {
        const data = res.data;
        data.score = data.plusCount - data.minusCount;
        resolve(data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export type IUserRank = {
  rank: number;
};

export const getUserRank = async (
  startDate: string,
  endDate: string,
  userId: UserID
): Promise<IUserRank> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`/users/${userId}/rank`, {
        params: {
          startDate,
          endDate,
          userId
        }
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export type IUserMonthsScore = {
  yearMonth: string;
  score: number;
  sentCount: number;
};
export const userMonthesScoresReport = async (
  userId: number,
  startDate: string,
  endDate: string
): Promise<IUserMonthsScore[]> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`/users/${userId}/score`, {
        params: {
          startDate,
          endDate
        }
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
