import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { ICustomTheme } from "styles/theme";

export const styles = makeStyles((theme: ICustomTheme) =>
  createStyles({
    skeletonWrapper: {
      height: "calc(100% - 72px)",
      overflow: "auto",
      position: "relative",
      "&::-webkit-scrollbar": {
        height: "15px",
        width: "15px"
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "transparent"
      },
      "&::-webkit-scrollbar-thumb": {
        border: "4px solid rgba(0, 0, 0, 0)",
        backgroundClip: "padding-box",
        borderRadius: "50px",
        backgroundColor: `${theme.colors.neutrals.neutrals_50} !important`,
        boxShadow:
          "inset -1px -1px 0px rgba(0, 0, 0, 0), inset 1px 1px 0px rgba(0, 0, 0, 0)"
      },
      "&::-moz-scrollbar": {
        height: "15px",
        width: "15px"
      },
      "&::-moz-scrollbar-track": {
        backgroundColor: "transparent"
      },
      "&::-moz-scrollbar-thumb": {
        border: "4px solid rgba(0, 0, 0, 0)",
        backgroundClip: "padding-box",
        borderRadius: "50px",
        backgroundColor: `${theme.colors.neutrals.neutrals_50} !important`,
        boxShadow:
          "inset -1px -1px 0px rgba(0, 0, 0, 0), inset 1px 1px 0px rgba(0, 0, 0, 0)"
      },
      "&::-ms-scrollbar": {
        height: "15px",
        width: "15px"
      },
      "&::-ms-scrollbar-track": {
        backgroundColor: "transparent"
      },
      "&::-ms-scrollbar-thumb": {
        border: "4px solid rgba(0, 0, 0, 0)",
        backgroundClip: "padding-box",
        borderRadius: "50px",
        backgroundColor: `${theme.colors.neutrals.neutrals_50} !important`,
        boxShadow:
          "inset -1px -1px 0px rgba(0, 0, 0, 0), inset 1px 1px 0px rgba(0, 0, 0, 0)"
      }
    },
    dividerSkeleton: {
      display: "flex",
      alignItems: "center",
      margin: "0 17px 24px 17px",
      "&:not(:first-of-type)": {
        margin: "24px 17px"
      },
      "& .MuiSkeleton-root": {
        backgroundColor: `${theme.colors.neutrals.neutrals_50} !important`,
        "&:after": {
          animation:
            "MuiSkeleton-keyframes-wave .9s linear 0.5s infinite !important"
        },
        "&:nth-child(2)": {
          minWidth: "160px",
          margin: "0 24px",
          borderRadius: "4px"
        }
      }
    },
    skeletonContainer: {
      backgroundColor: theme.colors.semantic.semantic_grey,
      display: "flex",
      alignItems: "center",
      padding: "16px",
      height: "87px",
      boxShadow: "0px 1px 20px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px",
      margin: "0 17px 16px 17px",
      "&:last-of-type": {
        display: "none"
      }
    },
    userImageSkeleton: {
      marginRight: "12px",
      borderRadius: "4px",
      minWidth: "54px",
      minHeight: "55px",
      backgroundColor: `${theme.colors.neutrals.neutrals_50} !important`,
      "&:after": {
        animation:
          "MuiSkeleton-keyframes-wave .9s linear 0.5s infinite !important"
      }
    },
    feedsCardContentSkeleton: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      width: "100%",
      height: "100%"
    },
    descriptionSkeletonContainer: {
      display: "flex",
      alignItems: "center",
      "& > span": {
        "&:nth-child(2)": {
          margin: "0 4px 0 9px"
        }
      }
    },
    textSkeleton: {
      borderRadius: "4px",
      backgroundColor: `${theme.colors.neutrals.neutrals_50} !important`,
      "&:after": {
        animation:
          "MuiSkeleton-keyframes-wave .9s linear 0.5s infinite !important"
      }
    }
  })
);
