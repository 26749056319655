import { useCurrentUser } from "app/appHooks";
import React from "react";
import { Outlet } from "react-router-dom";
import { UserRole } from "utils/constants";
interface Props {
  allowedRoles: UserRole[];
}
const RequireAuth = ({ allowedRoles }: Props) => {
  const { data: user } = useCurrentUser();
  const userRole = user?.role || "USER";
  return allowedRoles.includes(userRole) ? (
    <Outlet />
  ) : (
    <div> Not Allowed</div>
  );
};

export default RequireAuth;
