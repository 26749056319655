import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { PATH_NAME } from "routes/pathNames";
import { styles } from "./styles";
import HomeIcon from "assets/home.svg";
import ChartIcon from "assets/chart-2.svg";
import AccountIcon from "assets/profile.svg";
import LogoutAstronaut from "assets/LogoutAstronaut.svg";
import LogoutIcon from "assets/Logout.svg";
import SettingsIcon from "assets/setting-2.svg";
import LeaderboardIcon from "assets/leaderboard_icon.svg";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useCurrentUser } from "app/appHooks";
import { Modal, Typography, Button, SvgIcon } from "@mui/material";
import { Box, Stack } from "@mui/system";
import trackEvent from "utils/useAnalyticsEventTracker";
import RocketImg from "assets/rocket.png";
import { UserRoles } from "utils/constants";
function Sidebar({
  handleItemClick
}: {
  handleItemClick?: () => void;
}) {
  const classes = styles();
  const location = useLocation();
  const { data: user } = useCurrentUser();
  const [open, setOpen] = useState(false);
  const [selectedPage, setSelectedPage] = useState(
    `${location.pathname || PATH_NAME.NEWS_FEED}`
  );
  const navigate = useNavigate();
  useEffect(() => {
    setSelectedPage(`${location.pathname || PATH_NAME.NEWS_FEED}`);
  }, [location]);

  useEffect(() => {
    handleItemClick?.();
  }, [selectedPage]);

  const trackPageClickEvent = (page: string) => {
    let viewedPage = page;

    if (page.includes("/")) {
      viewedPage = page.split("/")[1];
    }

    trackEvent(`sidemenu_${viewedPage}_btn_clicked`, {
      type: "user_click"
    });
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleLogout = () => {
    trackPageClickEvent("logout");
    localStorage.removeItem("token");
    window.location.assign(PATH_NAME.HOME_PAGE);
  };

  const handleSelectPage = (page: string) => {
    setSelectedPage(page);
    trackPageClickEvent(page);
  };
  const [left, setLeft] = useState("0px");
  window.addEventListener("scroll", function (event) {
    setLeft("-" + window.scrollX + "px");
  });
  const onHelpCenterButtonClick = () => {
    setSelectedPage(PATH_NAME.HELP_CENTER);
    trackPageClickEvent(PATH_NAME.HELP_CENTER);
    navigate(PATH_NAME.HELP_CENTER);
  };
  return (
    <div style={{ left: left }} className={classes.mainContainer}>
      <div className={classes.mainContent}>
        <Link
          className={classes.pageLinks}
          to={PATH_NAME.NEWS_FEED}
          onClick={() => handleSelectPage(PATH_NAME.NEWS_FEED)}
        >
          <p
            className={
              selectedPage === PATH_NAME.NEWS_FEED ||
              selectedPage === PATH_NAME.HOME_PAGE
                ? classes.linkSelected
                : classes.links
            }
          >
            <span
              className={`${classes.LinkNameContainer} ${
                (location.pathname === PATH_NAME.NEWS_FEED ||
                  location.pathname === PATH_NAME.HOME_PAGE) &&
                classes.activeLink
              }`}
            >
              <img
                src={HomeIcon}
                alt="News Feed"
                className={classes.linkIcon}
              />
              <p>News Feed</p>
            </span>
          </p>
        </Link>
        <Link
          className={classes.pageLinks}
          to={PATH_NAME.LEADERBOARD}
          onClick={() => handleSelectPage(PATH_NAME.LEADERBOARD)}
        >
          <p
            className={
              selectedPage === PATH_NAME.LEADERBOARD
                ? classes.linkSelected
                : classes.links
            }
          >
            <span
              className={`${classes.LinkNameContainer} ${
                location.pathname === PATH_NAME.LEADERBOARD &&
                classes.activeLink
              }`}
            >
              <img
                src={LeaderboardIcon}
                alt="Leaderboard"
                className={classes.linkIcon}
              />
              <p>Leaderboard</p>
            </span>
          </p>
        </Link>

        <Link
          className={classes.pageLinks}
          to={`${PATH_NAME.ACCOUNT}/${user?.id}`}
          onClick={() => handleSelectPage(PATH_NAME.ACCOUNT)}
        >
          <p
            className={
              location.pathname.includes(PATH_NAME.ACCOUNT)
                ? classes.linkSelected
                : classes.links
            }
          >
            <span
              className={`${classes.LinkNameContainer} ${
                location.pathname.includes(PATH_NAME.ACCOUNT) &&
                classes.activeLink
              }`}
            >
              <img
                src={AccountIcon}
                alt="Account"
                className={classes.linkIcon}
              />
              <p>Account</p>
            </span>
          </p>
        </Link>
        {user?.role === UserRoles.COMPANY_ADMIN ? (
          <>
            <Link
              className={classes.pageLinks}
              to={PATH_NAME.INSIGHTS}
              onClick={() => handleSelectPage(PATH_NAME.INSIGHTS)}
            >
              <p
                className={
                  selectedPage === PATH_NAME.INSIGHTS
                    ? classes.linkSelected
                    : classes.links
                }
              >
                <span
                  className={`${classes.LinkNameContainer} ${
                    location.pathname === PATH_NAME.INSIGHTS &&
                    classes.activeLink
                  }`}
                >
                  <img
                    src={ChartIcon}
                    alt="Leaderboard"
                    className={classes.linkIcon}
                  />
                  <p>Insights</p>
                </span>
              </p>
            </Link>
            <Link
              className={classes.pageLinks}
              to={PATH_NAME.SETTINGS}
              onClick={() => handleSelectPage(PATH_NAME.SETTINGS)}
            >
              <p
                className={
                  selectedPage === PATH_NAME.SETTINGS
                    ? classes.linkSelected
                    : classes.links
                }
              >
                <span
                  className={`${classes.LinkNameContainer} ${
                    location.pathname === PATH_NAME.SETTINGS &&
                    classes.activeLink
                  }`}
                >
                  <img
                    src={SettingsIcon}
                    alt="Settings"
                    className={classes.linkIcon}
                    width={"30px"}
                    height={"30px"}
                  />
                  <p>Settings</p>
                </span>
              </p>
            </Link>
          </>
        ) : null}
        <div className={classes.separatorContainer}>
          <hr className={classes.separator} />
        </div>

        <button className={classes.logoutButton} onClick={handleOpen}>
          <span className={classes.LinkNameContainer}>
            <img
              src={LogoutIcon}
              alt="Logout"
              className={classes.linkIcon}
            />
            <p>Logout</p>
          </span>
        </button>
        <Stack className={classes.helpCenterCard}>
          <Box
            component={"img"}
            src={RocketImg}
            alt={"rocket img"}
            sx={{
              left: "30%",
              top: "-30%",
              position: "absolute"
            }}
          />

          <p className={classes.helpCenterDescription}>
            Do you have <br />
            Questions ?
          </p>
          <Button
            onClick={onHelpCenterButtonClick}
            className={classes.helpCenterButton}
          >
            Help Center
          </Button>
        </Stack>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Stack
            gap={2}
            position="relative"
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            className={classes.modalContainer}
          >
            <Box>
              <SvgIcon
                inheritViewBox
                onClick={handleClose}
                component={CloseRoundedIcon}
                className={classes.closeButton}
              />
            </Box>
            <Box component="img" src={LogoutAstronaut} alt="logout" />
            <Typography>Are you sure you want to logout?</Typography>
            <Stack
              gap={2}
              direction="row"
              justifyContent="space-between"
              className={classes.buttonGroup}
            >
              <Button variant="outlined" onClick={handleLogout}>
                Logout
              </Button>
              <Button variant="outlined" onClick={handleClose}>
                Stay & Give Kudos
              </Button>
            </Stack>
          </Stack>
        </Modal>
      </div>
    </div>
  );
}

export default Sidebar;
