import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { IDarkTheme } from "styles/darkTheme";

export const styles = makeStyles((theme: IDarkTheme) =>
  createStyles({
    mainContainer: {
      height: "100%",
      position: "fixed",
      width: "15%",
      maxWidth: "300px",
      "@media (max-width: 1370px)": {
        display: "none",
        width: "100%",
        height: "-webkit-fill-available"
      }
    },
    mainContent: {
      paddingBottom: "120px",
      overflowY: "auto",
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "start",
      alignItems: "start",
      height: "100%",
      width: "100%",
      "@media (max-width: 1370px)": { paddingBottom: "20px" }
    },
    closeButton: {
      color: "#92979C",
      fontSize: "16px",
      boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)"
    },
    pageLinks: {
      listStyleType: "none",
      display: "inline-block",
      color: theme.colors.semantic.semantic_grey,
      textDecoration: "none",
      margin: "15px 0px 15px 40px",
      height: "30px"
    },
    links: {
      fontSize: theme.fontSize.bodyText_1,
      fontFamily: theme.fontFamily.montserrat,
      opacity: "80%",
      fontWeight: theme.fontWeight.medium,
      display: "inline-block"
    },
    linkSelected: {
      display: "inline",
      color: theme.colors.secondary.secondary_50,
      fontSize: theme.fontSize.bodyText_1,
      fontFamily: theme.fontFamily.montserrat,
      opacity: "100%",
      fontWeight: theme.fontWeight.bold,
      position: "relative",
      textAlign: "center",
      verticalAlign: "top",
      "&::before": {
        position: "absolute",
        left: "-75px",
        top: "20%",
        content: '" "',
        width: "50px",
        height: "30px",
        background: theme.colors.secondary.secondary_50,
        transform: "rotate(135deg)",
        borderRadius: "6px"
      }
    },
    helpCenterCard: {
      padding: "14px 12px",
      width: "183px",
      height: "189px",
      borderRadius: "16px",
      marginTop: "auto",
      marginLeft: "20px",
      position: "relative",
      backgroundColor: theme.colors.secondary.secondary_50
    },
    helpCenterDescription: {
      fontSize: theme.fontSize.bodyText_2,
      fontWeight: theme.fontWeight.bold,
      color: theme.colors.semantic.semantic_white,
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: "auto",
      marginTop: "60px"
    },
    helpCenterButton: {
      width: "100%",
      padding: "8px",
      alignText: "center",
      textTransform: "none",
      borderRadius: "10px",
      fontSize: theme.fontSize.bodyText_2,
      fontWeight: theme.fontWeight.big,
      backgroundColor: theme.colors.semantic.semantic_white,
      color: theme.colors.secondary.secondary_50,
      "&:hover": {
        backgroundColor: theme.colors.semantic.semantic_white,
        color: theme.colors.secondary.secondary_50
      }
    },
    LinkNameContainer: {
      opacity: 0.7,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    activeLink: { opacity: 1 },
    linkIcon: {
      marginRight: "10px"
    },
    separatorContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%"
    },
    separator: {
      width: "80%",
      height: "1px",
      background: theme.colors.semantic.semantic_grey,
      opacity: "0.2"
    },
    logoutButton: {
      background: "transparent",
      border: "none",
      color: theme.colors.semantic.semantic_grey,
      textDecoration: "none",
      margin: "20px 0px 20px 40px",
      fontSize: theme.fontSize.bodyText_1,
      fontFamily: theme.fontFamily.montserrat,
      opacity: "80%",
      fontWeight: theme.fontWeight.medium,
      cursor: "pointer"
    },
    modalContainer: {
      padding: "32px",
      top: "35%",
      left: "35%",
      width: "520px",
      borderRadius: "16px",
      background: theme.colors.semantic.semantic_white,
      "@media (max-width: 1270px)": {
        left: "25%"
      },
      "@media (max-width: 750px)": {
        width: "370px",
        left: "4%"
      },
      "& > p": {
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "28px",
        "@media (max-width: 750px)": {
          fontSize: "16px"
        }
      },
      "& > div:first-of-type": {
        width: "32px",
        height: "32px",
        cursor: "pointer",
        position: "absolute",
        top: "-10px",
        right: "-10px",
        borderRadius: "50%",
        textAlign: "center",
        paddingTop: "5px",
        background: theme.colors.semantic.semantic_white,
        border: "1px solid #92979C"
      }
    },
    buttonGroup: {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "20px",
      "& button:last-of-type": {
        background: theme.colors.secondary.secondary_50,
        color: theme.colors.semantic.semantic_white
      },
      "& button": {
        borderRadius: "8px"
      },
      "& button:first-of-type": {
        borderColor: "black",
        color: "black"
      }
    }
  })
);
