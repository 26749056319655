export interface IDarkTheme {
  colors: {
    primary: {
      [key: string]: string;
    };
    secondary: {
      [key: string]: string;
    };
    neutrals: {
      [key: string]: string;
    };
    semantic: {
      [color: string]: string;
    };
  };
  fontSize: {
    [key: string]: string;
  };
  lineHeight: {
    [key: string]: string;
  };
  fontFamily: {
    montserrat: string;
    poppins : string;
  };
  fontWeight: {
    [key: string]: number;
  };
  inputs: {
    normal: {
      border: string;
    };
    hover: {
      border: string;
      boxShadow: string;
    };
    focus: {
      border: string;
      boxShadow: string;
    };
    error: { border: string };
  };
  modal: {
    boxShadow: string;
  };
}

const darkTheme: IDarkTheme = {
  colors: {
    primary: {
      primary_100: "#FD7670",
      primary_90: "#FC8B86",
      primary_80: "#FDA29E",
      primary_70: "#FDB8B5",
      primary_60: "#FED1CF",
      primary_50: "#FFECEB"
    },
    secondary: {
      secondary_110: "#884DC6",
      secondary_100: "#A65FF0",
      secondary_90: "#B884ED",
      secondary_80: "#CDB0EC",
      secondary_70: "#E8D9F8",
      secondary_60: "#E3E2FD",
      secondary_50: "#4F48F0",
      secondary_40: "#716BF3",
      secondary_35: "#A7A3F7",
      secondary_30: "#F4F3FF",
      secondary_20: "#B9B6F9"
    },
    neutrals: {
      neturals_100: "#4F4F4F",
      neutrals_90: "#2B2F32",
      neutrals_80: "#4E5358",
      neutrals_70: "#6F7B84",
      neutrals_60: "#B8C4CE",
      neutrals_50: "#DBE0E5",
      neutrals_40: "#ECEEF1",
      neutrals_30: "#FCFCFC",
      neutrals_20: "#F3F4F8",
      neutrals_10: "#F9F9F9"
    },
    semantic: {
      semantic_black: "#000000",
      semantic_yellow: "#FFC107",
      semantic_purple: "#8E8EF7",
      semantic_orange: "#FF8811",
      semantic_green: "#67CD84",
      semantic_error: "#F13D58",
      semantic_red: "#FF0000",
      semantic_grey: "#444444",
      semantic_white: "#FFFFFF",
      semantic_light_green: "#33C939"
    }
  },
  fontWeight: {
    light: 300,
    regular: 400,
    medium: 500,
    big: 600,
    bold: 700
  },
  fontFamily: {
    poppins: 'Poppins',
    montserrat: "Montserrat"
  },
  fontSize: {
    heading_1: "48px",
    heading_2: "47px",
    heading_3: "36px",
    heading_3_5: "32px",
    heading_4: "28px",
    heading_5: "24px",
    heading_7: "22px",
    heading_8: "20px",
    heading_9: "18px",
    bodyText_1: "16px",
    bodyText_2: "14px",
    bodyText_3: "12px"
  },
  lineHeight: {
    normal: "24px"
  },
  inputs: {
    normal: {
      border: "1px solid #B8C4CE"
    },
    hover: {
      border: "1px solid #B8C4CE",
      boxShadow: "0px 0px 0px 5px rgba(136, 77, 198, 0.3)"
    },
    focus: {
      border: "1px solid #884DC6",
      boxShadow: "0px 0px 0px 5px rgba(136, 77, 198, 0.3)"
    },
    error: {
      border: "1px solid #F13D58"
    }
  },
  modal: {
    boxShadow:
      "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)"
  }
};

export default darkTheme;
