import { codeRepeater } from "utils/helpers";
import { styles } from "./styles";
import CustomSkeleton from "../../SharedComponents/skeleton";
import Emoji from "react-emoji-render";

const NewsFeedSkeleton = () => {
  const classes = styles();

  return (
    <div className={classes.skeletonWrapper}>
      {codeRepeater(2).map(() => {
        return (
          <>
            <div className={classes.dividerSkeleton}>
              <CustomSkeleton width={"100%"} height={1} />
              <CustomSkeleton height={16} />
              <CustomSkeleton width={"100%"} height={1} />
            </div>

            <div className={classes.skeletonContainer}>
              <CustomSkeleton
                className={classes.userImageSkeleton}
                height={55}
                width={54}
              />
              <div className={classes.feedsCardContentSkeleton}>
                <CustomSkeleton
                  className={classes.textSkeleton}
                  height={15}
                  width={139}
                />
                <div className={classes.descriptionSkeletonContainer}>
                  <CustomSkeleton
                    className={classes.textSkeleton}
                    width={"100%"}
                    height={22}
                  />
                  <Emoji text={":star-struck:"} />
                  <Emoji text={":clap:"} />
                </div>
              </div>
            </div>

            <div className={classes.skeletonContainer}>
              <CustomSkeleton
                className={classes.userImageSkeleton}
                height={55}
                width={54}
              />
              <div className={classes.feedsCardContentSkeleton}>
                <CustomSkeleton
                  className={classes.textSkeleton}
                  height={15}
                  width={139}
                />
                <div className={classes.descriptionSkeletonContainer}>
                  <CustomSkeleton
                    className={classes.textSkeleton}
                    width={"50%"}
                    height={22}
                  />
                  <Emoji text={":victory_hand:"} />
                  <Emoji text={":dart:"} />
                </div>
              </div>
            </div>

            <div className={classes.skeletonContainer}>
              <CustomSkeleton
                className={classes.userImageSkeleton}
                height={55}
                width={54}
              />
              <div className={classes.feedsCardContentSkeleton}>
                <CustomSkeleton
                  className={classes.textSkeleton}
                  height={15}
                  width={139}
                />
                <div className={classes.descriptionSkeletonContainer}>
                  <CustomSkeleton
                    className={classes.textSkeleton}
                    width={"70%"}
                    height={22}
                  />
                  <Emoji text={":muscle:"} />
                  <Emoji text={":rocket:"} />
                </div>
              </div>
            </div>
          </>
        );
      })}
    </div>
  );
};

export default NewsFeedSkeleton;
