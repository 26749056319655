import LoadingAnimation from "components/SharedComponents/LoadingAnimation";
import NavBar from "components/SharedComponents/navBar";
import Sidebar from "components/SharedComponents/SideBar";
import React, { Suspense, useState } from "react";
import { Outlet } from "react-router-dom";
import { SearchContext } from "routes";
import { darkBackground } from "routes/darkBackground";
import { styles } from "./styles";

const AppLayout = () => {
  const classes = darkBackground();
  const classes2 = styles();
  const [searchTerm, setSearchTerm] = useState("");
  return (
    <div className={classes.mainContent}>
      <SearchContext.Provider value={{ searchTerm, setSearchTerm }}>
        <NavBar navBarTitle="Hoya App" />
        <div className={classes.contentWrapper}>
          <Sidebar />
          <main className={classes2.contentWrapper}>
            <div className={classes2.appContent}>
              <Suspense
                fallback={<LoadingAnimation isLoading={true} />}
              >
                <Outlet />
              </Suspense>
            </div>
          </main>
        </div>
      </SearchContext.Provider>
    </div>
  );
};

export default AppLayout;
